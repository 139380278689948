<template>
    <table class="table claimlist">
        <thead>
        <tr>
            <th class="media-only-desktop">id</th>
            <th></th>
            <th>Название</th>
            <!--th>Город</th-->
            <th class="media-only-desktop">Стадия</th>
            <th>Дедлайн стадии</th>
            <th class="media-only-desktop">Исполнитель</th>
            <th class="media-only-desktop">Истец</th>
            <th class="media-only-desktop">Ответчик</th>
            <th class="media-only-desktop">Арбитр</th>
            <!--th class="media-only-desktop">Дата рассмотрения</th-->
        </tr>
        </thead>
        <tbody>
        <tr v-for="claim in list" :class="{ 'dl-red' : claim.stage_dl_datediff < 2 && claim.stage_id != 7, 'dl-yel': claim.stage_dl_datediff == 2 && claim.stage_id != 7, 'dl-green':claim.stage_id == 7 || claim.stage_id == 8 || claim.stage_id == 9}">
            <td class="media-only-desktop">{{ claim.id }}</td>
            <td class="controls flex-st">
                <router-link :to="`/panel/claim/${claim.id}/view`" class="item list"><i
                    class="material-icons">description</i></router-link>
                <router-link :to="`/panel/claim/${claim.id}/edit`" class="item edit" v-if="$store.state.isAdmin() || $store.state.isImplementor()"><i
                    class="material-icons">edit</i></router-link>
            </td>
            <td><router-link :to="`/panel/claim/${claim.id}/view`">{{ claim.title }}</router-link></td>
            <!--td class="media-only-desktop">{{ claim.city_title }}</td-->
            <td class="media-only-desktop">{{ claim.stage_title }}</td>
            <td>{{ claim.stage_dl }}</td>
            <td class="media-only-desktop">{{ claim.implementer_name }}</td>
            <td class="media-only-desktop">{{ claim.claimant_name }}</td>
            <td class="media-only-desktop">{{ claim.defendant_name }}</td>
            <td class="media-only-desktop">{{ claim.arbiter_name }}</td>
            <!--td class="media-only-desktop">{{ claim.consideration_date }}</td-->
        </tr>
        </tbody>
    </table>
</template>

<script>
export default {
    name: "claimList",
    props:{
        list: Array,
    },
}
</script>

<style scoped lang="scss">
@import "../../assets/scss/variables";
tr {
    &.dl-red {
        background: lighten($cl-red, 30);
    }
    &.dl-yel {
        background: lighten($cl-yellow, 10);
    }
    &.dl-green {
        background: lighten($cl-green, 50);
    }
}
</style>