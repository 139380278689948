<template>
    <div class="upload">
        <label class="form">
            <input type="file" class="file" @change="upload" accept="video/*, audio/*, image/*, application/msword, application/vnd.ms-excel, text/plain, application/pdf"/>
            <div class="row flex">
                <div class="col-4">
                    <input v-model="title" placeholder="Название"/>
                </div>
                <div class="col-4">
                    <input v-model="number" placeholder="Номер входящего\исходящего"/>
                </div>
                <div class="col-4">
                    <div :class="['btn', 'blue', 'inb', {loading:loading}]">{{loading ? 'Загружаем...' : 'Загрузить'}}</div>
                </div>
            </div>
        </label>
    </div>
</template>

<script>
export default {
    name: "uploadFile",
    data() {
        return {
            file:false,
            title:'',
            number:'',
            loading:false,
        }
    },
    props: [
        'parent_type',
        'parent_id',
        'doc_type'
    ],
    methods: {
        upload() {
            this.loading = true;
            this.file =  this.$el.querySelector('input').files[0];
            let formData = new FormData();
            formData.append('file',this.file);
            formData.append('type',this.$props.doc_type);
            formData.append('title',this.title);
            formData.append('number',this.number);
            this.$api.post(`${this.$props.parent_type}/${this.$props.parent_id}/file`,formData,{
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(res => {
                this.loading = false;
                if (res.data.success) {
                    this.$store.commit('showNotify',{title:'Сохранено',content:'Файл успешно загружен',type:'success'});
                    this.title = '';
                    this.number = '';
                    this.$emit('uploaded');
                } else {
                    this.$store.commit('showNotify',{title:'Ошибка',content:res.data.error,type:'danger'});
                }
            })
        }
    }
}
</script>

<style scoped lang="scss">
.upload {
    .file {
        display: none;
    }
    .btn {
        transition: .2s;
        &.loading {opacity: 0.5;}
    }
}
</style>