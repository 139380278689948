<template>
    <div class="user-index">
        <div class="main-h1">Просмотр оплаты</div>
        <div class="card">
            <div class="card-title">{{ payment.title }}</div>
            <div class="card-body">
                <div class="card-body-title">Информация</div>
                <div class="row">
                    <div class="col-4">
                        <label class="form-label">
                            <div class="t">Пользователь</div>
                            <input v-model="payment.user_name" disabled/>
                        </label>
                    </div>
                    <div class="col-4">
                        <label class="form-label">
                            <div class="t">Сумма</div>
                            <input v-if="payment.amount" :value="parseInt(payment.amount).toLocaleString()" disabled/>
                        </label>
                    </div>
                    <div class="col-4">
                        <label class="form-label">
                            <div class="t">Направление</div>
                            <input :value="payment.direction_title" disabled/>
                        </label>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <div class="card-body-title">Статус</div>
                <div class="row">
                    <div class="col-6">
                        <label class="form-label">
                            <div class="t">Статус</div>
                            <input v-model="payment.status_title" disabled/>
                        </label>
                    </div>
                    <div class="col-6">
                        <label class="form-label">
                            <div class="t">Дедлайн статуса</div>
                            <input v-model="payment.status_dl" disabled/>
                        </label>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <div class="card-body-title">Документы</div>
                <file-list :list="files.filter(item => item.type_id == 1)" @updated="updateFiles"/>
                <upload-file doc_type="1" parent_type="payment" :parent_id="payment.id" @uploaded="updateFiles"/>
            </div>
            <div class="card-body">
                <div class="card-body-title">Связанные иски</div>
                <claim-list v-if="connected.length" :list="connected"/>
            </div>
        </div>
    </div>
</template>

<script>
import claimList from '../../../components/claim/list.vue';
import uploadFile from "../../../components/file/upload";
import fileList from "../../../components/file/list";
export default {
    name: "view",
    components:{claimList,fileList,uploadFile},
    data() {
        return {
            payment:{},
            files:[],
            connected:[],
        }
    },
    methods: {
        updateFiles() {
            this.getFiles();
        },
        getClaimConnected(claimId) {
            this.$api.get(`claim/${claimId}`).then(res => {
                if (res.data.success) {
                    this.connected.push(res.data.data)
                } else {
                    //this.$store.commit('showNotify',{title:'Ошибка',content:res.data.error,type:'danger'});
                }
            })
        },
        getPayment() {
            this.$api.get(`payment/${this.$route.params.id}`).then(res => {
                if (res.data.success) {
                    this.payment = res.data.data
                    if (res.data.data.claim_id) {
                        this.getClaimConnected(res.data.data.claim_id);
                    }
                } else {
                    //this.$store.commit('showNotify',{title:'Ошибка',content:res.data.error,type:'danger'});
                }
            })
        },
        getFiles() {
            this.$api.get(`payment/${this.$route.params.id}/file`).then(res => {
                if (res.data.success) {
                    this.files = res.data.data
                } else {
                    //this.$store.commit('showNotify',{title:'Ошибка',content:res.data.error,type:'danger'});
                }
            })
        },
    },
    created() {
        this.getPayment();
        this.getFiles();
    },
    watch: {
        '$route.params.id': function () {
            if (this.$route.params.id) {
                this.connected = [];
                this.getPayment();
                this.getFiles();
            }
        }
    }
}
</script>

<style scoped>

</style>